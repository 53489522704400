import * as React from 'react'
import { useDispatch } from 'react-redux'
import SEO from '../components/Commons/SEO'
import { login } from '../redux/thunks/security'
import { makeStyles } from '@material-ui/core'

import { FormEvent } from 'react'

const useStyles = makeStyles({
    main: {
        background: "#fafafa",
        height: "100vh"
    },
    box:{
        display:"flex",
        justifyContent: "center",
        paddingTop: 20,
    },
    root: {
        display:"flex",
        justifyContent:"center",
        background: "#ffffff",
        boxShadow: '0 3px 5px 2px #ededed',
        color: 'black',
        height: 300,
        width: 400,
        fontFamily: 'Roboto, sans-serif',
        
    },
    title: {
        width: "100%",
        textAlign: 'center',
        fontFamily: 'Roboto, sans-serif',  
        paddingTop: 20,
    },
    form:{
        display:"flex",
        flexDirection:"column",
        width:"75%",
        justifyContent:"center",
        alignContent:"center",
        alignItems:"center"
    }, 
    user:{
        outline: 0,
        background: "#f7f7f7",
        width: "100%",
        border: 0,
        margin: '0 0 15px',
        padding: '15px',
        fontSize:"15px",
        fontFamily: 'Roboto, sans-serif',
    },
    pass:{
        outline: 0,
        background: "#f7f7f7",
        width: "100%",
        border: 0,
        margin: '0 0 15px',
        padding: '15px',
        fontSize:"15px",
        fontFamily: 'Roboto, sans-serif',
    },
    button:{
        outline: 0,
        background: "#4CAF50",
        width: "100%",
        border: 0,
        padding: '15px',
        color: "#FFFFFF",
        fontSize: '15px',
        transition: "all 0.3 ease",
        cursor: "pointer",
        '&:hover': {
            boxShadow: '3px 0px 30px rgba(44, 171, 72, 0.5)',
        },       
    },
    buttonR:{
        outline: 0,
        background: "#4CAF50",
        width: "100%",
        border: 0,
        padding: '15px',
        color: "#FFFFFF",
        fontSize: '15px',
        transition: "all 0.3 ease",
        cursor: "pointer",
        '&:hover': {
            boxShadow: '3px 0px 30px rgba(44, 171, 72, 0.5)',
        },       
    },

    contenedorRegistrarse:{
        display: 'flex',
        textAlign: 'center',
    },

    register:{
        paddingLeft: "10px",
        paddingTop: "15px",
    },
});


const LoginPage = ():JSX.Element => {
    const dispatch = useDispatch()

    const classes = useStyles();
    function handlesubmit(e:FormEvent){
        e.preventDefault()

        const params={
            username:(document.getElementById("name") as HTMLInputElement).value,
            password:(document.getElementById("pass") as HTMLInputElement).value,
        }

        dispatch(login(params))
    }
    return (
        <>
            <SEO title="Login" />
            <div className={classes.main}>
                <div className={classes.title}>
                    <h2>Iniciar sesión</h2>
                </div>
                <div className={classes.box}>
                    <div className={classes.root}>
                        <form className={classes.form} onSubmit={(e: FormEvent) => handlesubmit(e)}>
                            <input className={classes.user} id="name" type="text" placeholder="Usuario" />
                            <input className={classes.pass} id="pass" type="Password" placeholder="Contraseña" />
                            <input className={classes.button} type="submit" value="INGRESAR" />
                            <div className={classes.contenedorRegistrarse}>
                                <p>No está registrado? </p>
                                <a className={classes.register} href="/Signup">Registrarse</a>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginPage
